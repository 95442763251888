import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import iconicHoldingLogo from './iconic-holding-logo-horizontal.png';
import './SignatureForm.scss'

export default class SignatureForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: '',
      title: '',
      emailAddress: '',
      phoneNumber: '',
      address: '',
      validated: null,
      fullNameError: null,
      titleError: null,
      emailAddressError: null,
      phoneNumberError: null,
      addressError: null      
    };

    this.updateState = this.updateState.bind(this);
    this.generateEmail = this.generateEmail.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCopySignatureHtmlButtonClick = this.handleCopySignatureHtmlButtonClick.bind(this);
    this.handleSelectSignatureButtonClick = this.handleSelectSignatureButtonClick.bind(this);
  }

  updateState(prop, value) {
    this.setState({ [prop]: value });
  }

  generateEmail() {
    const {
      props: {
        onChange
      },
      state: {
        fullName,
        title,
        emailAddress,
        phoneNumber,
        address      
      }
    } = this;

    onChange && onChange(fullName, title, emailAddress, phoneNumber, address);
  }

  handleFormSubmit(event) {
    const form = event.currentTarget
        , valid = form.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.updateState('formValidated', true);
    
    if (valid) {
      this.generateEmail();
    }
  }

  handleCopySignatureHtmlButtonClick() {
    const { onCopyHtml } = this.props;

    onCopyHtml && onCopyHtml();
  }

  handleSelectSignatureButtonClick() {
    console.warn('handleSelectSignatureButtonClick not implemented');
  }

  render() {
    const {
      updateState,
      handleFormSubmit,
      /*
      handleCopySignatureHtmlButtonClick,
      handleSelectSignatureButtonClick,
      */
      state: {
        fullName,
        title,
        emailAddress,
        phoneNumber,
        address,
        validated,
        fullNameError,
        titleError,
        emailAddressError,
        phoneNumberError,
        addressError      
      }
    } = this;

    return (
      <div className="signature-form">
        <div>
          <img className="signature-form__iconic-logo" alt="Iconic Holding" src={iconicHoldingLogo} />
        </div>
        <p>Welcome to the Iconic Holding email generator.</p>
        <p>
          <strong>1.</strong> Fill in the form below to generate your personalized email signature for Gmail or Outlook.<br />
          <strong>2.</strong> Select your signature and copy/paste it into your email client.
        </p>
        <br />
        <Form validated={validated} onSubmit={handleFormSubmit}>
          <Form.Row>
            {[{ 
              id: 'fullName',
              label: "Full name",
              value: fullName,
              required: true,
              error: fullNameError
            }, { 
              id: 'title',
              label: "Title",
              value: title,
              required: true,
              error: titleError
            }, { 
              id: 'emailAddress',
              label: "Email address",
              value: emailAddress,
              required: true,
              error: emailAddressError
            }, { 
              id: 'phoneNumber',
              label: "Phone number",
              value: phoneNumber,
              required: true,
              error: phoneNumberError
            }, { 
              id: 'address',
              label: "Address",
              value: address,
              required: true,
              error: addressError
            }].map(({ id, label, value, required, type="text", error }, index) => (
              <Form.Group controlId={id} key={`signature-form-group${index}`}>
                <Form.Control required={required} placeholder={label + '..'} type={type} value={value} onChange={({ target: { value } }) => updateState(id, value)} />
                <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
              </Form.Group>
            ))}
          </Form.Row>
          <Form.Row>
            <Button variant="primary" type="submit">Generate email</Button>
            {/*
              &nbsp;
              <Button variant="secondary" onClick={handleSelectSignatureButtonClick}>Select Signature</Button>
              &nbsp;
              <Button variant="secondary" onClick={handleCopySignatureHtmlButtonClick}>Copy HTML</Button>
            */}
          </Form.Row>
        </Form>
      </div>
    );
  }
}
