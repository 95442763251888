import React from 'react';

export default React.forwardRef(({ fullName, title, emailAddress, phoneNumber, address }, ref) => {
	return (
		<div ref={ref} style={{ fontSize: 'initial', color: '#212529' }}>
			<table style={{ width: '800px', marginTop: '48px', fontSize: '14px', fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif' }} cellPadding="0" cellSpacing="0">
			  <tbody>
			    <tr>
			      <td style={{ verticalAlign: 'top', paddingRight: '32px', paddingBottom: '16px', borderBottom: '1px solid rgb(67,72,81)' }}>
			        <div style={{ fontSize: '1.5rem', fontWeight: '600', color: 'rgb(138,171,209)' }}>{fullName}</div>
			        <div style={{ fontSize: '1rem' }}>{title}</div>
			        <table style={{ marginTop: '16px', width: '100%' }}>
			          <tbody>
			            <tr>
			              <td style={{ verticalAlign: 'top', paddingBottom: '8px', color: 'rgb(138,171,209)' }}>e:</td>
			              <td style={{ verticalAlign: 'top', paddingBottom: '8px' }}>
			              	<a href={`mailto:${emailAddress}`} style={{ textDecoration: 'none', color: '#212529' }}>{emailAddress}</a>
			              </td>
			            </tr>
			            <tr>
			              <td style={{ verticalAlign: 'top', paddingBottom: '8px', color: 'rgb(138,171,209)' }}>m:</td>
			              <td style={{ verticalAlign: 'top', paddingBottom: '8px' }}>{phoneNumber}</td>
			            </tr>
			            <tr>
			              <td style={{ verticalAlign: 'top', color: 'rgb(138,171,209)' }}>a:</td>
			              <td style={{ verticalAlign: 'top' }}>{address}</td>
			            </tr>
			            <tr>
			              <td style={{ textAlign: 'right', paddingTop: '32px' }} colSpan="2">
			                <a style={{ marginRight: '8px', textDecoration: 'none' }} href="https://facebook.com/iconiclabvc">
			                  <img alt="Facebook" style={{ height: '1.1rem' }} src="https://iconicholding.com/wp-content/uploads/2019/03/email-sig-facebook.png" />
			                </a>
			                <a style={{ marginRight: '8px', textDecoration: 'none' }} href="https://twitter.com/iconiclabvc">
			                  <img alt="Twitter" style={{ height: '1.1rem' }} src="https://iconicholding.com/wp-content/uploads/2019/03/email-sig-twitter.png" />
			                </a>
			                <a style={{ marginRight: '8px', textDecoration: 'none' }} href="https://t.me/iconicholdingchat">
			                  <img alt="Telegram" style={{ height: '1.1rem' }} src="https://iconicholding.com/wp-content/uploads/2019/03/email-sig-social-telegram.png" />
			                </a>
			                <a style={{ marginRight: '8px', textDecoration: 'none' }} href="https://linkedin.com/company/iconicholding">
			                  <img alt="LinkedIn" style={{ height: '1.1rem' }} src="https://iconicholding.com/wp-content/uploads/2019/03/email-sig-linkedin.png" />
			                </a>
			              </td>
			            </tr>
			          </tbody>
			        </table>
			      </td>
			      <td style={{ width: '255px', borderBottom: '1px solid #2a2e3a', backgroundColor: '#454952', textAlign: 'center', padding: '20px 16px 8px 16px' }}>
			        <a href="https://iconicholding.com" style={{ color: '#ffffff', textDecoration: 'none' }}>
			          <img alt="Iconic Holding" style={{ border: 0, height: '128px', marginBottom: '28px' }} src="https://iconicholding.com/wp-content/uploads/2018/09/Iconic_Holding_w-768x684.png" />
			          <div style={{ fontSize: '0.8rem', textDecoration: 'none' }}>www.iconicholding.com</div>
			        </a>
			      </td>
			    </tr>
			    <tr>
			      <td style={{ fontSize: '0.7rem', lineHeight: '20px' }} colSpan="2">
			        <br />
			        <div style={{ textAlign: 'justify' }}><strong>Disclaimer:</strong> This message contains confidential and privileged information. If you are not the addressee of the message you must not use, copy, disclose or take any action based on this message or any information herein. If you have received this message as an error, please let the sender know immediately by replying to this e-mail and delete this message. Unless otherwise stated, this message is neither an offer nor the solicitation of an offer to purchase or sell any investment.</div>
			        <br />
			        <div><strong>Managing Partners: Patrick Lowry, Maximilian Lautenschläger | Register court: AG Frankfurt/Main | Register number: HRB 109756</strong></div>
			      </td>
			    </tr>
			  </tbody>
			</table>
		</div>
	);
});