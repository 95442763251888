import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import Signature from './Signature';
import './SignaturePreview.scss';

export default class SignaturePreview extends Component {
  constructor(props) {
    super(props);

    this.signatureRef = React.createRef();
  }

  componentDidUpdate() {
    const { onRender } = this.props;

    onRender && onRender(this.signatureRef.current);
  }

  render() {
    const {
      signatureRef,
      props: { fullName, title, emailAddress, phoneNumber, address }
    } = this;

    return (
      <div>
      	<Container className="signature-preview__container">
      		{fullName && title && emailAddress && phoneNumber && address &&
  	    		<Signature
              ref={signatureRef}
              fullName={fullName}
              title={title}
              emailAddress={emailAddress}
              phoneNumber={phoneNumber}
              address={address} />
      		}
      	</Container>
      </div>
    );
  }	
}
