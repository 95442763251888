import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SignatureForm from './SignatureForm';
import SignaturePreview from './SignaturePreview';
import './App.scss';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.signatureDom = null;
    this.state = {
      fullName: '',
      title: '',
      emailAddress: '',
      phoneNumber: '',
      address: ''
    };

    this.handleSignatureFormChange = this.handleSignatureFormChange.bind(this);
    this.handleSignatureRender = this.handleSignatureRender.bind(this);
    this.handleSignatureCopyHtml = this.handleSignatureCopyHtml.bind(this);
  }

  handleSignatureFormChange(fullName, title, emailAddress, phoneNumber, address) {
    this.setState({ fullName, title, emailAddress, phoneNumber, address });
  }

  handleSignatureRender(signatureDom) {
    this.signatureDom = signatureDom;
  }

  handleSignatureCopyHtml() {
    console.log(this.signatureDom);
  }

  render() {
    const {
      handleSignatureFormChange,
      handleSignatureRender,
      handleSignatureCopyHtml,
      state: {
        fullName,
        title,
        emailAddress,
        phoneNumber,
        address
      }
    } = this;

    return (
      <Container className="full-height" fluid>
        <Row className="full-height">
          <Col className="form-container full-height" sm={4}>
            <SignatureForm
              onChange={handleSignatureFormChange}
              onCopyHtml={handleSignatureCopyHtml} />
          </Col>
          <Col className="preview-container full-height" sm={8}>
            <SignaturePreview
              fullName={fullName}
              title={title}
              emailAddress={emailAddress}
              phoneNumber={phoneNumber}
              address={address}
              onRender={handleSignatureRender} />
          </Col>
        </Row>
      </Container>
    );
  }
}
